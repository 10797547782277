<template>
    <div>
        <b-navbar toggleable="md" type="light" variant="white" fixed="top">
            <b-navbar-brand href="#">
                <img src="../assets/logo.png" alt="" class="logo">
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item
                        class="menu_nav_item"
                        :class="currentMenu == index ? 'currentMenu login-box':''"
                        v-for="(item,index) in menuList"
                        :key="index"
                        @click.self="changeMenu(index,item.routerName)"
                    >
                    {{$t(item.name)}}</b-nav-item>
<!--                    <b-nav-item class="menu_nav_item" @click="changeMenu(3,null)">{{$t('Calculate')}}</b-nav-item>-->
<!--                    <b-nav-item class="menu_nav_item" @click="changeMenu(4,'/historicalData')">{{$t('HistoricalData')}}</b-nav-item>-->
                    <b-nav-item class="menu_nav_item" @click="changeLan">{{$t('language')}}</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
export default {
    name: "Header",
    props:{
        currentMenuNm:Number
    },
    watch:{
        currentMenuNm:function (newVal,oldVal) {
            if(newVal != oldVal){
                this.currentMenu = newVal
            }
        }
    },
    data(){
        return{
            menuList:[
                {
                    routerName:'/aboutTheIndex',
                    name:'aboutTheIndex'
                },    {
                    routerName:'/methodology',
                    name:'methodology'
                },  {
                    routerName:'/howToUse',
                    name:'howToUse'
                },
                {
                    routerName:'/calculate',
                    name:'calculate'
                },  {
                    routerName:'/historicalData',
                    name:'historicalData'
                },
            ],
            currentMenu:0,
            language:''
        }
    },
    created() {
        let lan = sessionStorage.getItem('CICLanguage') || 'EN_US';

        this.language = lan;
        this.$i18n.locale = lan;
        sessionStorage.setItem('CICLanguage',lan);
        // console.log(lan)

        let path = this.$route.path;
        for (let i = 0; i < this.menuList.length; i++) {
          if (this.menuList[i].routerName === path) {
            this.currentMenu = i;
          }
        }
    },
    mounted(){

    },
    methods:{
        changeMenu(index,routerName){
            if(index == 3){
                let href = window.location.href.split('#')[0];
                let newHref = href + '#/calculate';
                window.open(newHref);
            }else{
                this.currentMenu = index;
                sessionStorage.setItem('CICRouterName',routerName);
                this.$emit('changeRouter',routerName);
                this.$router.push(routerName);
            }

        },
        changeLan(){
            if(this.language == 'EN_US'){
              sessionStorage.setItem('CICLanguage','ZH_HK');
            }else{
              sessionStorage.setItem('CICLanguage','EN_US');
            }
            window.location.reload()
        }
    }
}
</script>

<style scoped lang='less'>
.navbar-light .navbar-nav .currentMenu .nav-link:focus,
.navbar-light .navbar-nav .currentMenu .nav-link:hover,
.navbar-light .navbar-nav .currentMenu .nav-link{
    color:#89C639;
    font-weight: bold;

}
.currentMenu {
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    background: #89C639;
    height: 2px;
    animation: btnAnim 1s linear;
  }

  // border-bottom: 3px solid #8bb31e;
}
.logo{
    width: 160px;
}
.menu_nav_item {
    margin: 0 10px;
    font-weight: 500;
    a:hover  {
        color: #89C639 !important;
    }
}
@media screen and (max-width: 768px) {
    .logo{
        width: 120px;
    }
    .nav-item.menu_nav_item{
        height: 50px;
        line-height: 36px;
        margin: 0;

    }
}
@keyframes btnAnim {
  0% {
    width: 0;
  }
  50% {
    width: 0;
  }
  75% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}


</style>

<style>
.login-box a {
  position: relative;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  width: 100%;
  height: 100%;
}
.login-box a span {
  position: absolute;
  display: block;
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #89C639);
  animation: btn-anim1 1s linear ;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #89C639);
  animation: btn-anim2 1s linear ;
  animation-delay: .25s
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #89C639);
  animation: btn-anim3 1s linear ;
  animation-delay: .5s
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #89C639);
  animation: btn-anim4 1s linear ;
  animation-delay: .75s
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}
</style>
