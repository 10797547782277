module.exports = {
    SpecificYear: 'Specific Year',
    Year: 'Year',
    CTP: "The CTP index of each year will be calculated as an average of the cumulative project CTP values of the year (i.e. Yearly CTP Index). The graph shows the trend of Yearly CTP Index with the value in 2015 as the 'Base Point' of the Index (Base Point = 100). If the CTP Index of the year is higher than 2015 (i.e. 100), it means the construction time performance of the year is faster than the construction time performance of 2015, vice versa.",
    aboutTheIndex:"About the Index",
    methodology: 'Methodology',
    howToUse: 'How to use ',
    calculate: 'Calculate',
    historicalData: 'Historical data',
    language: '中文',
    MethodologyTitle: 'CIC CONSTRUCTION TIME PERFORMANCE INDEX',
    SubmitTitle1:"Gain an objective and independent perspective on how your projects construction time performed compared with other similar projects completed in Hong Kong.",
    SubmitTitle2:"Start your journey with the CIC Construction Time Performance Index.",
    Boundary: 'Boundary',
    BoundaryText1: 'The Tool is designed to evaluate the construction time performance of Hong Kong building superstructure construction projects via machine learning methods based on parameters such as GFA, final contract sum, etc. ',
    BoundaryText2: 'The Tool is specifically developed to be used on projects in Hong Kong only.',
    Parameters: 'Parameters',
    ParametersText: 'The Tool covers the following key parameters that influence the performance of building superstructure projects: Final Contract Sum, Gross Floor Area, Number of Storey, Number of Podium Levels, Number of Basement Levels, Number of Blocks, Inclement Weather, Availability of Skilled Labour and Vacancies of Manual Workers at Construction Sites.',
    UserGuidesTitle: 'USER GUIDES AND TUTORIALS',
    UserGuidesText1: 'The Tool aims to be simple and user friendly. To assist you further we have developed User Guides and Tutorials.',
    UserGuidesText2: 'A trial data set is also available for you to experiment with the Tool as you explore the further.',
    UserGuide: 'User Guide',
    Tutorial: 'Tutorial',
    TrialData: 'Trial Data',
    ConstructionTimePerformance: 'CONSTRUCTION TIME PERFORMANCE (CTP)',
    BuildingInformation: 'Building Information',
    RemarkInfo: 'Remarks: Please input Date of Submission of BA10 / Notice of Commencement of Building Works as Start Date and the Date of Occupation Permit as End Date.',
    CalculateBtn: 'Calculate',
    EstimatedConstructionPerformanceCPIndex: 'Construction Time Performance Index',
    ConstructionTimePerformanceIndex:"Construction Time Performance Index",
    ECPIInfo: "Construction Time Performance index (hereinafter referred to as 'index') is used to measure the construction time performance of each project based on ratio of model prediction value against the actual project duration. The index > 100 means the actual project duration is shorter than the model prediction, while the index <100 means the actual project duration is longer than the model prediction.",
    buildingTypes: 'Building Type',
    Residential: 'Residential',
    Commercial: 'Commercial',
    Mixed: 'Mixed',
    ruleRange: "The range is between ",
    ForExample:'for example ',
    BuildingType:"Building Type",
    GFA: "Gross Floor Area (m2)",
    ruleGFA: "Gross floor area cannot be empty",
    BuildingStorey: "Number of Storey",
    ruleBuildingStorey: "Number of storey cannot be empty",
    BasementStorey: "Number of Basement Levels",
    ruleBasementStorey: "Number of basement levels cannot be empty",
    PodiumStorey: "Number of Podium Levels",
    rulePodiumStorey: "Number of podium levels cannot be empty",
    Blocks: "Number of Blocks",
    ruleBlocks: "Number of blocks cannot be empty",
    ContractSum: "Final Contract Sum (HKD in million)",
    ruleContractSum: "Final contract sum cannot be empty",
    ruleContractSumRange: "The range is between 30 million - 4000 million",
    StartDate: "Start Date",
    ruleStartDate: "Start date cannot be empty",
    EndDate: "End Date",
    ruleEndDate: "End date cannot be empty",
    CICVideoTitle: "CIC Research Study - Introductory Video",
    UserLogin:"Please enter your user information",
    FirstName:"First Name",
    LastName:"Last Name",
    Email:"E-mail",
    Company:"Company",
    JobTitle:"Job Title",
    Support:"Support",
    IHaveRead:"I have read and understood the",
    PleaseCheck:"* Please Check ",
    PleaseEnter:"Please Enter ",
    IncorrectEmailFormat:'Incorrect email format',
    //about the tool
    Start:'START',
    Back:"BACK",
    Next:"NEXT",
    AboutTheToolTitle:"ABOUT THE INDEX",
    AboutToolText1:'Construction duration on a building project is the time determined to complete the scope of works specified in a works contract. It is an important performance criterion to measure the project success and the contractor’s fulfilment of the contract requirements to timely complete the works. It is a common practice to predict the duration required for building construction works based on oneself/one organisation’s experience of similar projects. Although this approach is widely accepted and used in the construction industry, the project source available for reference is limited and the risk in construction duration may not be "fairly" allocated to both sides.',
    AboutToolText2:"This Index is developed with the big data analytics and machine learning approach and can be used for evaluating and benchmarking the construction time performance of different building superstructure projects in Hong Kong. By comparing one individual’s project construction time performances with similar past projects in Hong Kong, this Index can be an effective tool in gaining an objective and independent perspective on how their projects construction time performed compared with other similar projects completed in Hong Kong.",
    FunctionText1:"Based on big data analytics and machine learning algorithms",
    FunctionText2:"User friendly dashboard to demonstrate the CTP trend in the past years",
    FunctionText3:"Benchmark for new buildings and evaluation for completed buildings",
    FunctionText4:"Identifying key parameters that influence the construction time performance",
    ThirdPartyText:"The Index has been developed based on past projects and understanding of over 150 organizations.",
    DesignedTo:"THE INDEX IS DESIGNED TO",
    PersonalDataCollectionStatement:'Personal Data Collection Statement',
    PersonalDataCollectionStatement1:'The information you provide to the Construction Industry Council (the CIC),\n' +
        'including any personal data as defined in the Personal Data (Privacy)\n' +
        'Ordinance (the Ordinance), will be used solely for purposes related to the\n' +
        'activities of the CIC.',
    PersonalDataCollectionStatement2:'To keep you informed of CIC activities and industry developments which\n' +
        'may be of interest, the CIC would like to use your personal data, including\n' +
        'your name, phone number and correspondence and email addresses, to\n' +
        'update you in relation to training courses, trade testing, registration, events\n' +
        'and other aspects of its work and the construction industry. ',
    PersonalDataCollectionStatement3:'You are free to decide whether you wish to receive such information. If you\n' +
        'choose not to do so, please put a tick in the box below.',
    PersonalDataCollectionStatement4:'You are also entitled to request access to and correction of any errors in your\n' +
        'personal data. If you wish to do so please write to the CIC at 38/F, COS\n' +
        'Centre, 56 Tsun Yip Street, Kwun Tong, Kowloon.\n',
    IAgreePersonalDataCollectionStatement:"I do not wish to receive any information from the CIC in relation to its\n" +
        "activities or developments in the construction industry\n",
    Disclaimer:'DISCLAIMER',
    DisclaimerContent1:'All information or material on this website including in particular the information relating to the past and the construction time performance index (the "Information") is for general reference purposes only and does not amount to any offer or invitation to treat or any direct or indirect responsibility to users. The Information only caters for the general situation and does not adapt for any particular circumstances of any particular individual. Users are responsible for making their own assessments of the Information and should verify the Information or seek appropriate independent professional advice relevant to their particular circumstances before acting on the Information. The Information is not a substitute for independent professional advice. The Construction Industry Council ("CIC") shall not be responsible for use of or reliance on, or decision made on the basis of, the Information.',
    DisclaimerContent2:'Whilst CIC endeavours to ensure the accuracy of the Information, no statement, representation, warranty or guarantee of any kind, whether express or implied, is given by CIC as to its accuracy, timing, completeness, satisfactory quality, fitness for a particular purpose or appropriateness for use of the Information in any particular circumstances. CIC shall not be liable for any errors, misrepresentations or omissions in or in connection with the Information.',
    DisclaimerContent3:'The Information relates to Hong Kong only and CIC makes no warranty or representation that the Information is appropriate for or in other places or jurisdictions.',
    DisclaimerContent4:'To the extent permitted by law, CIC expressly excludes any liability for any expense, loss or damage including, without limitation, direct, indirect or consequential loss or damage, or any expense, loss or damage whatsoever arising from use of, or inability to use, the Information, whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable, provided that this condition does not affect CIC\'s liability for death or personal injury arising from CIC\'s negligence, nor CIC\'s liability for fraudulent misrepresentation, nor any other liability which cannot be excluded or limited under the laws of Hong Kong which shall apply to all matters, disputes or claims arising from or relating to the Information including this disclaimer.',
    Agree:"Agree",
    PleaseComplete:"Please Complete !"

};
