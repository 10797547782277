
<template>
    <div class="about">
        <b-container class="bv-example-row ">
            <div class="about_title">{{$t('AboutTheToolTitle')}}</div>
            <b-row>
                <b-col lg="5" md="12" >
                    <img src="../assets/bg2.jpg" alt="" class="about_img" :style="language == 'EN_US'? {'margin':'50px 0 '}:{}">
                </b-col>
                <b-col lg="7" md="12">
                    <p class="about_text" :style="language == 'EN_US'? {}: {'line-height':'2.4'}">{{$t('AboutToolText1')}}</p>
                    <p class="about_text" :style="language == 'EN_US'? {}: {'line-height':'2.4'}">{{$t('AboutToolText2')}}</p>
                </b-col>
            </b-row>
            <b-row class="rowPadding">
                <b-col lg="3" md="12" >
                    <i class="mdi mdi-chart-scatter-plot mdiStyle"></i>
                    <p class="function_text">{{$t('FunctionText1')}}</p>
                </b-col>
                <b-col lg="3" md="12" >
                    <i class="mdi mdi-monitor-dashboard mdiStyle" ></i>
                    <p class="function_text">{{$t('FunctionText2')}}</p>
                </b-col>
                <b-col lg="3" md="12" >
                    <i class="mdi mdi-calculator-variant-outline mdiStyle"></i>
                    <p class="function_text">{{$t('FunctionText3')}}</p>
                </b-col>
                <b-col lg="3" md="12" >
                    <i class="mdi mdi-bullseye-arrow mdiStyle" ></i>
                    <p class="function_text">{{$t('FunctionText4')}}</p>
                </b-col>
            </b-row>
            <b-row class="rowPadding2">
                <b-col lg="6" md="12" >
                    <img src="../assets/bg5.jpg" alt="" class="about_img">
                </b-col>
                <b-col lg="6" md="12">
                    <img v-if="language == 'EN_US'" src="../assets/ThirdParty-Eng.png" alt="" class="thirdParty_img">
                    <img v-else src="../assets/ThirdParty-chi.png" alt="" class="thirdParty_img">
                    <p class="thirdParty_text">{{$t('ThirdPartyText')}}</p>
                </b-col>

            </b-row>
            <div class="about_title">{{$t('DesignedTo')}}</div>
            <img v-if="language == 'EN_US'" src="../assets/DesignedTo-Eng.png" alt="" class="designedTo_img">
            <img v-else src="../assets/DesignedTo-Chi.png" alt="" class="designedTo_img">

        </b-container>
    </div>
</template>

<script>
    export default {
        name: "AboutTheTool",
        data(){
            return{
                language:"",
            }
        },
        created() {
            let lan  = sessionStorage.getItem('CICLanguage');
            this.language = lan;
        }
    }
</script>

<style scoped lang="less">
.textStyle{
    font-weight: 300;
    margin-bottom: 10px;
    font-size: 16px;
}
.about{
    background-color: #FFF;
    padding: 110px 0;
    .rowPadding{
        padding: 180px 0 200px 0;
    }
    .rowPadding2{
        padding-bottom: 120px;
    }
    .about_title{
        font-weight: 700;
        font-size: 40px;
        margin-bottom: 50px;
    }
    .about_img{
        width: 100%;
    }
    .about_text{
       .textStyle();
        text-align: justify;
    }
    .function_text{
        .textStyle();
        text-align: center;
        max-width: 300px;
        margin: 0 auto;
    }
    .thirdParty_text{
        .textStyle();
        text-align: center;
        max-width: 500px;
        margin: 0 auto;
    }
    .mdiStyle{
        font-size: 100px;
        color: rgb(137, 198, 57);
    }
    .thirdParty_img{
        width:60%;
        margin-bottom: 10px;
    }
    .designedTo_img{
        width: 75%;
        margin: 20px 0;
    }


}
@media screen and (max-width: 768px) {
    .about{

        .rowPadding{
            padding: 60px 0;
        }
        .about_img{
            margin:  50px 0;
        }
        .designedTo_img{
            width: 100%;
            margin: 20px 0;
        }
    }
}
@media screen and (max-width: 414px) {
    .about{
        padding: 50px 0;
        .rowPadding{
            padding: 40px 0;
        }
        .about_title{
            font-weight:  600;
            font-size: 32px;
            margin-bottom: 20px;
        }
    }

}
</style>
