<template>
  <div class="methodology">
      <div class="methodology_content">
          <b-container class="bv-example-row ">
              <b-row>
                  <b-col lg="6" md="12">
                      <div class="methodology_textItem">
                          <h2 class="methodology_textTitle">{{$t('Boundary')}}</h2>
                          <p class="methodology_text">{{$t('BoundaryText1')}}</p>
                          <p class="methodology_text">{{$t('BoundaryText2')}}</p>
                      </div>
                      <br/>

                      <div class="methodology_textItem">
                         <h2 class="methodology_textTitle">{{$t('Parameters')}}</h2>
                        <p class="methodology_text">{{$t('ParametersText')}}
                        </p>
                      </div>
                  </b-col>
                  <b-col lg="6" md="12" class="methodology_img">
                      <img style="width: 100%" v-if="language == 'EN_US'" src="../assets/CIC-English.svg" alt="">
                      <img style="width: 100%" v-else src="../assets/CIC-Chinese.svg" alt="">
                  </b-col>
              </b-row>
          </b-container>

      </div>
  </div>
</template>

<script>
    export default {
        name: "AssessmentMethod",
        data(){
            return{
                language:''
            }
        },
        created() {
            let lan = sessionStorage.getItem('CICLanguage') || 'EN_US';
            this.language = lan;
        }
    }
</script>

<style lang="less">
.methodology_content{
    background-color: #FFF;
    padding: 110px 0;
}
.methodology_textItem{
    text-align: left;
    /*padding-left: 20px;*/
}
.methodology_textTitle{
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 20px;
}
.methodology_text{
    font-weight: 300;
    margin-bottom: 10px;
    font-size: 16px;
    text-align: justify;
}
.methodology_img{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {
    /*ipad*/
    .methodology_img{
        padding-top: 40px;
    }
}
@media screen and (max-width: 414px) {

    .methodology_content{
        padding: 50px 0;
    }
    .methodology_textTitle{
        font-weight:  600;
        font-size: 32px;
        margin-bottom: 20px;
    }
    .methodology_text{
        font-weight: 300;
        margin-bottom: 10px;
        font-size: 14px;
    }
}
</style>
