<template>
  <div class="howToUse">
      <div class="howToUse_content">
<!--          <iframe-->
<!--                  style="width: 100%; height: 55vh"-->
<!--                  src="https://www.youtube.com/watch?v=P-Nd-yy2sNE"-->
<!--                  title="YouTube video player"-->
<!--                  frameborder="0"-->
<!--                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
<!--                  allowfullscreen-->
<!--          ></iframe>-->

          <b-container class="bv-example-row ">
              <b-row>
                  <b-col lg="7" md="12">
                      <div class="howToUse_textItem">
                          <h2 class="howToUse_textTitle">{{$t('UserGuidesTitle')}}</h2>
                          <p class="howToUse_text">{{$t('UserGuidesText1')}}</p>
                          <p class="howToUse_text">{{$t('UserGuidesText2')}}</p>
                      </div>
                  </b-col>
                  <b-col lg="5" md="12" class="howToUse_img">
                      <img style="width: 100%" src="../assets/books.png" alt="">
                  </b-col>
              </b-row>
              <b-row >
                  <b-col lg="4" md="12" class="howToUse_UserGuides">
                      <a :href="guidePDF" :download="guidePDF" target="_blank">
                          <i class="mdi mdi-book-open-page-variant-outline mdiStyle" ></i>
                          <p>{{$t('UserGuide')}}</p>
                      </a>
                  </b-col>
                  <b-col lg="4" md="12" class="howToUse_UserGuides">
                      <a href="https://www.youtube.com/watch?v=H2gOrcSNE9k" target="_blank">
                          <i class="mdi mdi-television-play mdiStyle" ></i>
                          <p>{{$t('Tutorial')}}</p>
                      </a>
                  </b-col>
                  <b-col lg="4" md="12" class="howToUse_UserGuides">
                      <a :href="trialExcel" :download="trialExcel">
                          <i class="mdi mdi-folder-table-outline mdiStyle" ></i>
                          <p>{{$t('TrialData')}}</p>
                      </a>
                  </b-col>
              </b-row>
          </b-container>
      </div>
  </div>
</template>

<script>
    import URLOBJ from '../config/apiUrl'
    export default {
        name: "UsageMethod",
        data(){
            return{
                tutorialsList:[
                    {
                        img:'@/assets/icon1.png',
                        value:'d'
                    },{
                        img:'../assets/icon2.png',
                        value:''
                    },{
                        img:'../assets/icon3.png',
                        value:''
                    },
                ],
                trialExcel:'',
                guidePDF:''
            }
        },
        created() {
            let lan = sessionStorage.getItem('CICLanguage');
            // let href = window.location.href.split('#')[0];
            // console.log(href)
            let Href = URLOBJ.file;
            if(lan == 'EN_US'){
                this.guidePDF = Href + '/CIC_User_Guide_English.pdf';
                this.trialExcel = Href + '/CIC(CTP)_Sample_data(EN).xlsx';
            }else{
                this.guidePDF = Href + '/CIC_User_Guide_Chinese.pdf';
                this.trialExcel = Href + '/CIC(CTP)_Sample_data(中文).xlsx'
            }
        }
    }
</script>

<style scoped lang="less">
    .howToUse_content{
        background-color: #FFF;
        padding: 100px 0;
    }
    .howToUse_textItem{
        text-align: left;
        padding-left: 20px;
        font-size: 16px;
    }
    .howToUse_textTitle{
        font-weight: 700;
        font-size: 40px;
        margin-bottom: 20px;
    }
    .howToUse_text{
        font-weight: 300;
        line-height: 1.7;
    }
    .howToUse_img{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .howToUse_UserGuides{
        cursor: pointer;
        padding: 30px 0;
        img{
            width: 100px;
        }

    }
    .howToUse_UserGuides:hover{
        box-shadow: 2px 2px 14px 0 rgba(137,197,58,0.3);
        border-radius: 10px;
    }

    @media screen and (max-width: 768px) {
        /*ipad*/
        .howToUse_img{
            padding-top: 40px;
        }
    }
    @media screen and (max-width: 414px) {

        .howToUse_content{
            padding: 50px 0;
        }
        .howToUse_textTitle{
            font-weight:  600;
            font-size: 32px;
            margin-bottom: 20px;
        }
        .howToUse_text{
            font-weight: 300;
            margin-bottom: 10px;
            font-size: 14px;
        }
    }
</style>
