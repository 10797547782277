const URLOBJ = {
  api: 'https://dev.arupneuron.com/',
  localhost: '',
  type:''
}

function checkApiUrl (URLOBJ) {
  // var nowUrl = window.location.host;
  // let href = window.location.href;
  // let localhost = '';
  // if (href.indexOf('?') !== -1) {
  //   localhost = href.split('?')[0]
  //   let num = localhost.indexOf('#')
  //   if (num !== -1) {
  //     URLOBJ.localhost = localhost.substring(0, num - 1)
  //   }
  // } else if (href.indexOf('#')) {
  //   URLOBJ.localhost = href ? href.split('#')[0] : ''
  // }

  // if (/localhost/.test(href)) {
  //   URLOBJ.flow = 'https://emsd.arupneuron.com/'
  // } else {
  //   URLOBJ.flow = URLOBJ.localhost + 'flow/'
  // }
  var nowUrl = window.location.host;
  var domain = nowUrl.split("/");

  const protocol = window.location.protocol + "//"; //获取协议
  const IP = nowUrl.split(":")[0]; //获取IP地址

  if (domain[0] && /10.200.1.40/.test(domain[0])) {
    URLOBJ.api = 'http://10.201.0.110:27001/';
    URLOBJ.file = 'http://10.200.1.40';
    URLOBJ.type = 'uat';
  }else if(domain[0] && /ctpindex.cic.hk/.test(domain[0])) {
    URLOBJ.api = 'https://ctpindex.cic.hk/api/';
    URLOBJ.file = 'http://ctpindex.cic.hk';
    URLOBJ.type = 'prod';
  }else if(domain[0] && /dev.arupneuron.com/.test(domain[0])) {
    URLOBJ.api = 'https://dev.arupneuron.com/';
    URLOBJ.file = 'https://dev.arupneuron.com/cicdemo';
    URLOBJ.type = 'dev';
  }else {
    // URLOBJ.api = protocol + IP + "/";
    // URLOBJ.type = 'prod';
    URLOBJ.api = 'https://ctpindex.cic.hk/api/';
    URLOBJ.file = 'http://ctpindex.cic.hk';
    URLOBJ.type = 'prod';
    // URLOBJ.api = 'https://dev.arupneuron.com/';
    // URLOBJ.type = 'dev';

    //
    //
  }
  return URLOBJ;
}
checkApiUrl(URLOBJ);
export default URLOBJ
