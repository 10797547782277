<template>
  <div>
    <div class="methodology_content">
      <div class="charts">
        <!-- <Spin v-if="loading" ></Spin> -->
        <div class="important_value">
          <h2 class="methodology_textTitle">CTP Index</h2>
        </div>
        <Row style="margin: 20px 0">
          <Col span="24">
            <p class="methodology_text" style="text-align: left">
              {{ $t("CTP") }}
            </p>
          </Col>
        </Row>
        <div style="margin-top: 20px">
          <Form ref="formInline" :model="queryForm" inline>
            <FormItem style="margin-right: 40px">
              <div class="selectTitle">{{ $t("Year") }}</div>
              <InputNumber
                :max="2019"
                :min="2009"
                v-model="queryForm.SDate"
                placeholder="Start date"
                style="width: 130px; margin-right: 10px"
                @on-change="SdateChange"
              ></InputNumber>
              <InputNumber
                :max="2019"
                :min="2009"
                v-model="queryForm.EDate"
                placeholder="End date"
                style="width: 130px"
                @on-change="EdateChange"
              ></InputNumber>
              <slider
                v-model="queryForm.year"
                range
                :step="1"
                :min="2009"
                :max="2019"
                show-stops
                @on-change="sliderChange"
                class="slider_color"
              ></slider>
            </FormItem>
            <FormItem>
              <div class="selectTitle">{{ $t("SpecificYear") }}</div>
              <Select
                v-model="queryForm.years"
                multiple
                style="max-width: 500px; min-width: 200px"
                @on-change="yearsChange"
              >
                <Option v-for="item in yearList" :value="item" :key="item">{{
                  item
                }}</Option>
              </Select>
            </FormItem>
          </Form>
        </div>
        <div class="chartsItme">
          <div style="margin-top: 20px">
            <baseChart
              :chartID="'Residential'"
              :title="''"
              :chartData="chartObj"
              :color="'#ff5a87'"
              @setOption="getSetOptionFn"
            />
          </div>
        </div>
      </div>
    </div>
    <b-modal id="videoModal" :title="$t('CICVideoTitle')" size="lg" hide-footer>
      <div style="min-height: 55vh">
        <iframe
                style="width: 100%; height: 55vh"
                src="https://www.youtube.com/embed/YI-RHOf8beY"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
        ></iframe>
      </div>
    </b-modal>
    <!-- User Modal -->
<!--    <Modal-->
<!--      v-model="showLogin"-->
<!--      :closable="false"-->
<!--      :mask-closable="false"-->
<!--      footer-hide-->
<!--      :styles="{ top: '10rem' }"-->
<!--    >-->
<!--      &lt;!&ndash; <b-modal id="userModal" title="Login" hide-footer> &ndash;&gt;-->
<!--      <h3 style="text-align: center">{{$t('UserLogin')}}</h3>-->
<!--      <Divider />-->
<!--      <Form-->
<!--        :model="userForm"-->
<!--        :label-width="100"-->
<!--        :rules="ruleLogin"-->
<!--        ref="userForm"-->
<!--      >-->
<!--        <FormItem :label="$t('LastName')" prop="lastName">-->
<!--          <Input v-model="userForm.lastName"-->
<!--          ></Input>-->
<!--        </FormItem>-->
<!--        <FormItem :label="$t('FirstName')" prop="firstName">-->
<!--          <Input-->
<!--                  v-model="userForm.firstName"-->
<!--          ></Input>-->
<!--        </FormItem>-->
<!--        <FormItem :label="$t('Company')" prop="company">-->
<!--          <Input-->
<!--                  v-model="userForm.company"-->
<!--          ></Input>-->
<!--        </FormItem>-->
<!--        <FormItem prop="checkbox">-->
<!--          <Checkbox v-model="userForm.checkbox"  @on-change="changeValue">-->
<!--            <span>{{$t('IHaveRead')}}</span>-->
<!--          </Checkbox>-->
<!--          <a :href="disclaimerHref" target="blank">{{$t('Disclaimer')}}</a>-->
<!--          <p v-show="checkDisclaimer" class="remind">{{$t('PleaseCheck')}}{{$t('Disclaimer')}}</p>-->
<!--        </FormItem>-->
<!--        <Button-->
<!--          long-->
<!--          ref="userForm"-->
<!--          type="success"-->
<!--          :loading="loginFormLoading"-->
<!--          @click="login"-->
<!--          style="background-color: #89c672;border: 1px solid #89c672;margin-bottom: 10px"-->
<!--        >-->
<!--          Login-->
<!--        </Button>-->
<!--      </Form>-->
<!--      &lt;!&ndash; </b-modal> &ndash;&gt;-->
<!--    </Modal>-->
  </div>
</template>
<script>
let chartDate = [
  {
    name: "Residential",
    color: "rgb(88, 202, 252)",
    data: {
      2009: 104.8925,
      2010: 103.7787,
      2011: 105.6631,
      2012: 102.9961,
      2013: 104.0743,
      2014: 103.7305,
      2015: 100,
      2016: 98.21512,
      2017: 104.1606,
      2018: 96.49084,
      2019: 100.4361,
    },
  },
  {
    name: "Commercial",
    color: "rgb(132, 255, 218)",
    data: {
      2009: 99.61527,
      2010: 99.64654,
      2011: 94.75698,
      2012: 99.71499,
      2013: 100.2038,
      2014: 100.0286,
      2015: 100,
      2016: 96.66394,
      2017: 93.309,
      2018: 92.33058,
      2019: 96.10465,
    },
  },
  {
    name: "Mixed",
    color: "rgb(255, 156, 162)",
    data: {
      2009: 109.7154,
      2010: 102.6254,
      2011: 109.2233,
      2012: 109.5095,
      2013: 104.655,
      2014: 104.8005,
      2015: 100,
      2016: 103.3492,
      2017: 105.1676,
      2018: 102.3273,
      2019: 101.4102,
    },
  },
];
import baseChart from "@/components/baseChart.vue";
export default {
  data() {
    return {
      loading: false,
      queryForm: {
        year: [2009, 2019],
        years: [],
        SDate: 2009,
        EDate: 2019,
      },
      yearList: [],
      chartObj: [],
      setOptionsFn: null,
      userForm: {
        firstName: "",
        lastName: "",
        company: "",
        checkbox: false,
      },
      ruleLogin: {
        firstName: [
          {
            required: true,
            message: "First name cannot be empty",
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "last name cannot be empty",
            trigger: "blur",
          },
        ],
        company: [
          {
            required: true,
            message: "Company cannot be empty",
            trigger: "change",
          },
        ],
        // checkbox: [
        //   {
        //     required: true,
        //     type: "number",
        //     message: "Contract Duration cannot be empty",
        //     trigger: "blur",
        //   },
        // ],
      },
      loginFormLoading: false,
      checkDisclaimer:false,
      // showLogin:false
    };
  },
  methods: {
    init() {
      this.$set(this, "queryForm", {
        year: [2009, 2019],
        years: [],
        SDate: 2009,
        EDate: 2019,
      });
      this.initYears();
      let chartObj = this.chartObj;
      chartDate.map((item) => {
        let tempDate = [];
        let datas = item.data;
        for (let k in datas) {
          tempDate.push(datas[k]);
        }
        chartObj.push({
          name: item.name,
          color: item.color,
          data: tempDate,
        });
      });
    },
    // showlogin() {
    //   this.showLogin = true;
    // },
    showVideo() {
      this.$bvModal.show("videoModal");
    },
    initYears() {
      let min = 2009;
      let max = 2019;
      let yearList = this.yearList;
      let years = this.queryForm.years;
      while (min <= max) {
        yearList.push(min);
        years.push(min);
        min++;
      }
    },
    sliderChange(arr) {
      if (arr.leng && arr.length != 2) {
        return;
      }
      let sDate = arr[0];
      let eDate = arr[1];
      this.$set(this.queryForm, "SDate", sDate);
      this.$set(this.queryForm, "EDate", eDate);
      this.getChartXAxisCategoryData();
    },
    yearsChange(arr) {
      this.getChartXAxisCategoryData(arr);
    },
    SdateChange() {
      this.getChartXAxisCategoryData();
      this.$set(this.queryForm.year, 0, this.queryForm.SDate);
    },
    EdateChange() {
      this.getChartXAxisCategoryData();
      this.$set(this.queryForm.year, 1, this.queryForm.EDate);
    },
    getChartXAxisCategoryData(arr) {
      let tempArr = [];
      if (arr && arr.length) {
        this.setChartDate(arr);
      } else {
        let min = this.queryForm.SDate;
        let max = this.queryForm.EDate;
        while (min <= max) {
          tempArr.push(min);
          min++;
        }
        this.setChartDate(tempArr);
      }
    },
    setChartDate(arr) {
      arr.sort(); //按年份排序
      let xAxisCategories = [];
      let series = [];
      arr.map((item) => {
        xAxisCategories.push(item);
      });
      chartDate.map((chartItem) => {
        let tempArr = [];
        for (let i = 0; i < arr.length; i++) {
          tempArr.push(chartItem.data[arr[i]]);
        }
        series.push({
          name: chartItem.name,
          color: chartItem.color,
          data: tempArr,
        });
      });

      let obj = {
        series: series,
        xAxisCategories: xAxisCategories,
      };
      this.setOptionsFn(obj);
    },
    getSetOptionFn(fn) {
      this.setOptionsFn = fn;
    },
    // hiddenLogin() {
    //   // this.$bvModal.hide("chartModal");
    //   this.showLogin = false;
    // },
    login() {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          if(this.userForm.checkbox){
            this.loginFormLoading = true;
            setTimeout(() => {
              this.loginFormLoading = false;
              this.hiddenLogin();
              this.showVideo();
            }, 100);
          }else{
            this.checkDisclaimer = true
          }
        }
      });
    },
    changeValue(status){
      this.checkDisclaimer = !status;
    },
  },
  created() {
    this.init();
  },
  mounted() {
    // this.showlogin();
  },
  components: {
    baseChart,
  },
};
</script>
<style lang="less" scoped>
</style>
<style lang="less" >
.charts {
  max-width: 1000px;
  margin: 0 auto;
  // color: #fff;
  background: rgb(255 255 255 / 95%);
  padding: 20px;
  max-height: 100%;
  overflow: auto;
  .important_value {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    h2 {
      font-weight: 700;
      font-size: 40px;
      margin-bottom: 20px;
    }
    label {
      font-size: 20px;
      margin-bottom: 0;
    }
    span {
      font-size: 32px;
      margin-left: 10px;
    }
  }
  .formTitle {
    position: relative;
    margin: 20px 0;
    font-size: 24px;
    .line {
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#ec0f51),
        to(#17ccfb)
      );
      background: linear-gradient(90deg, #ec0f51, #17ccfb);
      height: 2px;
      width: 30%;
      top: 40px;
      left: 0;
      position: absolute;
    }
  }
  .slider_color {
    .ivu-slider-bar {
      background: #89c672 !important;
    }
    .ivu-slider-button {
      border: 2px solid #89c672 !important;
    }
  }
  .selectTitle {
    line-height: 30px;
    text-align: left;
    color: #89c672;
  }
  // .ivu-select-multiple .ivu-select-item-selected {
  //   color: rgba(45, 140, 240, 0.9);
  //   background: #404f5d;
  // }
}
</style>
