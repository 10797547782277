import Vue from "vue";
import Router from "vue-router";

import Main from "@/page/main";
import AboutTheIndex from "@/page/AboutTheIndex";
import Methodology from "@/page/Methodology";
import HowToUse from "@/page/HowToUse";
import Calculate from "@/page/Calculate";
import HistoricalData from "@/page/HistoricalData";


const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "Main",
      component: Main,
      children: [
        {
          path: "/AboutTheIndex",
          name: "AboutTheIndex",
          component: AboutTheIndex
        }, {
          path: "/Methodology",
          name: "Methodology",
          component: Methodology
        }, {
          path: "/HowToUse",
          name: "HowToUse",
          component: HowToUse
        },{
          path: "/calculate",
          name: "Calculate",
          component: Calculate
        },{
          path: "/historicalData",
          name: "HistoricalData",
          component: HistoricalData
        },
      ]
    },
  ]
});
