module.exports = {
    SpecificYear: '指定年份',
    Year: '年份',
    CTP:'年度CTP指數為該年度累計項目的CTP平均值。下圖顯示了年度CTP指數的趨勢，以2015年的值作為該指數的“基點”（基點 = 100）。如果該年度的CTP指數高於2015年（即100），則表示該年度的平均施工期表現比2015年的短，反之亦然。',
    aboutTheIndex:"關於",
    methodology:'評估方法',
    howToUse:'使用方法',
    calculate:'計算工具',
    historicalData:'歷史數據',
    language:'Eng',
    MethodologyTitle:'建造業議會施工期表現指數',
    SubmitTitle1:"透過本工具建立的基準能有助瞭解你的項目的施工期表現並提升建築項目效率",
    SubmitTitle2:"立即登記使用建造業議會施工期表現評估",
    Boundary:'範圍 ',
    BoundaryText1:'建造業議會施工期表現指數工具利用機器學習方法，根據建築項目性質如總建築樓面面積（GFA），總合約金額等對建築項目施工期表現行評估。',
    BoundaryText2:'建造業議會施工期表現指數工具只適用於香港建築項目。',
    Parameters:'參數',
    ParametersText:'本工具對項目進行評估時基於以下參數：總合約金額、總建築面積、樓層層數、平台層數、地庫層數、座數、惡劣天氣、可用熟練工人及建築工人空缺。',
    UserGuidesTitle:'用戶指南和教學',
    UserGuidesText1:'此工具著重操作簡易及方便。議會提供用戶指南和教學以協助你使用此工具。',
    UserGuidesText2:'你也可使用試驗數據來作進一步探索。',
    UserGuide:'用戶指南',
    Tutorial:'教學',
    TrialData:'試驗數據',
    ConstructionTimePerformance:'施工期表現 (CTP)',
    BuildingInformation:'建築物資訊',
    RemarkInfo:'備註：請以註冊承建商聘任通知，展開建築工程或街道工程通知及註冊承建商承擔責任書（BA10）表格的提交日期作為開始日期，並以佔用許可證發出日期作為結束日期。',
    CalculateBtn:'計算',
    ConstructionTimePerformanceIndex:"施工期表現指數",
    ECPIInfo:"施工期表現指數（以下簡稱“指標”）根據模型預測值與實際項目施工期的比率來評估每個項目的施工期表現。指數大於100表示​​實際項目施工期短於模型的預測結果，而指數小於100表示​​實際項目施工期長於模型的預測結果。",
    buildingTypes:'建築類型',
    Residential:'住宅',
    Commercial:'商業',
    Mixed:'混合用途',
    ruleRange:"填寫範圍:",
    BuildingType:"建築類型",
    GFA:"縂樓面面積(㎡)",
    ruleGFA:"請填寫縂樓面面積",
    ForExample:'例如',
    BuildingStorey:"樓層層數",
    ruleBuildingStorey:"請填寫樓層層數",
    BasementStorey:"地庫層數",
    ruleBasementStorey:"請填寫地庫層數",
    PodiumStorey:"平台層數",
    rulePodiumStorey:"請填寫平台層數",
    Blocks:"座數",
    ruleBlocks:"請填寫座數",
    ContractSum:"總合約金額（百萬港幣)",
    ruleContractSum:"請填寫總合約金額",
    ruleContractSumRange:"填寫範圍: 30 - 4000 (百萬)",
    StartDate:"開始日期",
    ruleStartDate:"請選擇開始日期",
    EndDate:"結束日期",
    ruleEndDate:"請選擇結束日期",
    CICVideoTitle:"CIC研究學習 - 介紹視頻",
    UserLogin:"請輸入用戶信息",
    FirstName:"名稱",
    LastName:"姓氏",
    Email:"郵箱",
    Company:"公司名稱",
    JobTitle:"職稱",
    Support:"支援",
    IHaveRead:"我已閱讀並理解",
    PleaseCheck:"* 請勾選",
    PleaseEnter:"請輸入",
    IncorrectEmailFormat:'請輸入郵箱格式',
    //about the tool
    Start:'開始',
    Back:"返回",
    Next:"下一步",
    AboutTheToolTitle:"關於",
    AboutToolText1:"建築項目的施工期是指工程合約中列明完成工程範疇所需的期限。施工期是一個用來評估項目成功與否以及承建商是否按合約要求及時完成工程的重要表現指標。建築業通常會根據個人或公司在類似項目中的經驗來估算施工期。雖然業界已廣泛接受和使用此估算方法，但可供參考的項目數量有限，施工期中的風險可能未能夠「公平地」分配給雙方。",
    AboutToolText2:"施工期表現（CTP）指數使用大數據分析和機器學習方法開發，可用於評估和基準化香港不同上蓋建築項目的建築時間表現。透過比較單一項目和香港過往類似項目的施工期表現，本指數能夠成為一種有效的工具，為項目施工期表現提出客觀和獨立的看法。",
    FunctionText1:"基於大數據技術和機器學習算法",
    FunctionText2:"提供一個簡易且用戶友好的可視化界面",
    FunctionText3:"為新項目提供基準，現有項目提供評估",
    FunctionText4:"識別出影響建築時間變現的重要參數",
    ThirdPartyText:"建造業議會施工期表現指數是基於香港過往真實建築項目和超過150個業界組織的智識和協作開發而成",
    DesignedTo:"建造業議會施工期表現指數旨在",
    PersonalDataCollectionStatement:'《收集個人資料聲明》',
    PersonalDataCollectionStatement1:'你向建造業議會〔「 議 會 」〕所提供的資料，包括《個人資料〔私隱〕條例》所指的個人資料，只會用於相關議會之活動。',
    PersonalDataCollectionStatement2:'為讓你得知最新的議會活動和行業內發展情況，議會將使用你的個人資料，包括你的姓名、電話號碼、郵寄和電郵地址，將有關訓練課程、測試、註冊、活動項目、議會工作和建造業其他方面的最新資訊提供給你 。',
    PersonalDataCollectionStatement3:'你可選擇是否同意接收上述資訊。若不同意的話，請於下列有關拒收資訊一欄之空格內加上「 √ 」號。',
    PersonalDataCollectionStatement4:'你有權要求查閱及修正你的個人資料。有關申請須以書面向建造業議會提出,地址為九龍觀塘駿業街56號中海日升中心38樓。',
    IAgreePersonalDataCollectionStatement:"本人不同意日後接收由建造業議會發出有關議會活動和與建造業相關的資訊。",
    Disclaimer:'免責聲明',
    DisclaimerContent1:'本網站的所有資料或材料，特別包括有關過往及建造業議會施工期表現指數的資料（「有關資料」），僅供一般參考之用，概不構成任何向使用者提出的要約或要約邀請或任何向使用者承擔的直接或間接責任。有關資料僅供一般情況的使用，並無考慮任何個別人士有關任何特殊情況的需要。使用者就有關資料採取任何行動前，應對有關資料自行作出評估及進行核實，或就本身特殊情況尋求適當的獨立專業意見。所提供的有關資料並非獨立專業意見的替代品。建造業議會就任何對有關資料的使用或依賴或據以作出的決定，概不承擔任何責任。',
    DisclaimerContent2:'建造業議會盡力確保有關資料準確，惟就有關資料的準確性、時效、完整性、具備令人滿意的質量、對特殊用途的適用性或其在任何特殊情況下的使用的適當性，建造業議會並沒有作出任何性質的聲明、陳述、保證或擔保，不論是否明示或隱含。建造業議會對於有關資料或與其有關的任何錯誤、失實陳述或遺漏，概不承擔任何責任。',
    DisclaimerContent3:'有關資料僅僅與香港情況有關，對於有關資料在其他地方或司法管轄區是否適合使用，建造業議會並無作出任何保證或陳述。',
    DisclaimerContent4:'在法律許可的範圍内，對於任何開支、損失或損害，包括但不限於任何直接、間接或相應損失或損害，或任何由於有關資料的使用或無法使用而產生的開支、損失或損害，不論是否基於侵權法（包括疏忽）、違約或其他原因，即使是可以預見者，建造業議會在此明確卸除任何責任；惟本條款並無影響建造業議會就任何由於其疏忽所導致的死亡或人身傷害而須承擔的責任或具欺詐成份的失實陳述的責任或任何其他根據香港法例不能卸除或限制的責任；香港法例適用於所有由於有關資料而產生或有關的事項、爭議或申索，包括本免責聲明。',
    Agree:"同意",
    PleaseComplete:"請完成表單!"
};
