<template>
  <div class="calculate">

    <Spin size="large" fix v-if="loading"></Spin>
    <section class="section-calculate content">
      <div class="container">
        <h2 class="methodology_textTitle">
          {{ $t("ConstructionTimePerformance") }}
        </h2>
        <Form
          :model="ForecastForm"
          ref="ForecastForm"
          :rules="ruleForecast"
          label-position="top"
        >
          <Divider>{{ $t("BuildingInformation") }}</Divider>
          <FormItem :label="$t('BuildingType')" prop="buildingTypes" class="ivu-form-item-required">
            <RadioGroup v-model="ForecastForm.buildingTypes">
              <Radio :label="0">{{ $t("Residential") }}</Radio>
              <Radio :label="1">{{ $t("Commercial") }}</Radio>
              <Radio :label="2">{{ $t("Mixed") }}</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem :label="$t('GFA')" prop="GFA">
            <InputNumber
              style="width: 100%"
              :min="0"
              v-model="ForecastForm.GFA"
              :placeholder="$t('PleaseEnter') + $t('GFA')+ ' , ' + $t('ForExample') + '30000'"
            ></InputNumber>
          </FormItem>
          <FormItem :label="$t('BuildingStorey')" prop="noOfStoreyBuilding">
            <InputNumber
              style="width: 100%"
              :min="0"
              v-model="ForecastForm.noOfStoreyBuilding"
              :placeholder="$t('PleaseEnter') + $t('BuildingStorey')+ ' , ' + $t('ForExample') + '30'"
            ></InputNumber>
          </FormItem>
          <FormItem :label="$t('BasementStorey')" prop="noOfStoreyBasement">
            <InputNumber
              style="width: 100%"
              :min="0"
              v-model="ForecastForm.noOfStoreyBasement"
              :placeholder="$t('PleaseEnter') + $t('BasementStorey')+ ' , ' + $t('ForExample') + '3'"
            ></InputNumber>
          </FormItem>
          <FormItem :label="$t('PodiumStorey')" prop="noOfStoreyPodium">
            <InputNumber
              style="width: 100%"
              :min="0"
              v-model="ForecastForm.noOfStoreyPodium"
              :placeholder="$t('PleaseEnter') + $t('PodiumStorey')+ ' , ' + $t('ForExample') + '1'"
            ></InputNumber>
          </FormItem>
          <FormItem :label="$t('Blocks')" prop="noOfBlocks">
            <InputNumber
              style="width: 100%"
              :min="0"
              v-model="ForecastForm.noOfBlocks"
              :placeholder="$t('PleaseEnter') + $t('Blocks')+ ' , ' + $t('ForExample') + '1'"
            ></InputNumber>
          </FormItem>
<!--          <Divider>{{ $t("ContractInformation") }}</Divider>-->
          <FormItem :label="$t('ContractSum')" prop="contractSum">
            <InputNumber
              style="width: 100%"
              :min="0"
              v-model="ForecastForm.contractSum"
              :placeholder="$t('PleaseEnter') + $t('ContractSum')+ ' , ' + $t('ForExample') + '30'"
            ></InputNumber>
          </FormItem>
          <FormItem :label="$t('StartDate')" prop="startDate">
            <DatePicker
              type="month"
              placeholder="Select date"
              v-model="ForecastForm.startDate"
              style="width: 100%"
              @on-change="checkMonth"
            ></DatePicker>
          </FormItem>
          <FormItem :label="$t('EndDate')" prop="endDate">
            <DatePicker
              type="month"
              placeholder="Select date"
              v-model="ForecastForm.endDate"
              @on-change="checkMonth"
              style="width: 100%"
            ></DatePicker>
          </FormItem>
          <div
            style="
              color: #808695;
              margin-bottom: 20px;
              font-size: 16px;
              font-weight: 300;
            "
          >
            {{ $t("RemarkInfo") }}
          </div>
          <Button
            long
            type="success"
            @click="submitForecastForm"
            style="float: right;background-color: #89c672;border: 1px solid #89c672;"
            >{{ $t("CalculateBtn") }}</Button>
        </Form>
        <forecastResult :monthNum="months" id="forecastResult"></forecastResult>
      </div>
    </section>

    <b-modal id="videoModal" :title="$t('CICVideoTitle')" size="lg" hide-footer>
      <div style="min-height: 55vh">
        <iframe
          style="width: 100%; height: 55vh"
          src="https://www.youtube.com/embed/H2gOrcSNE9k"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
<!--        https://www.youtube.com/embed/YI-RHOf8beY-->
<!--        https://www.youtube.com/watch?v=P-Nd-yy2sNE-->
      </div>
    </b-modal>
    <!-- User Modal -->
    <Modal
      v-model="showLogin"
      :mask-closable="false"
      width="1000"
      footer-hide

      @on-visible-change="visibleChange"
    >
<!--       <b-modal id="userModal" title="Login" hide-footer> -->
      <b-row v-if="modelPage == 1">
        <h3 style="margin:0 auto;color: rgb(98, 138, 33);" class="model_title">{{$t('UserLogin')}}</h3>
        <Divider />
        <b-col lg="6" md="12">
          <Form :model="userForm" :label-width="100" :rules="ruleLogin" ref="userForm"
          >
            <FormItem :label="$t('LastName')" prop="lastName">
              <Input v-model="userForm.lastName"
              ></Input>
            </FormItem>
            <FormItem :label="$t('FirstName')" prop="firstName">
              <Input
                      v-model="userForm.firstName"
              ></Input>
            </FormItem>
            <FormItem :label="$t('Email')" prop="email">
              <Input
                      v-model="userForm.email"
              ></Input>
            </FormItem>
            <FormItem :label="$t('Company')" prop="company">
              <Input
                      v-model="userForm.company"
              ></Input>
            </FormItem>
            <FormItem :label="$t('JobTitle')" prop="jobTitle">
              <Input
                      v-model="userForm.jobTitle"
              ></Input>
            </FormItem>
<!--            <FormItem prop="checkbox">-->
<!--              <Checkbox v-model="userForm.checkbox"  @on-change="changeValue">-->
<!--                <span>{{$t('IHaveRead')}}</span>-->
<!--              </Checkbox>-->
<!--&lt;!&ndash;              <a v-if="language == 'EN_US'" href="http://www.cic.hk/eng/main/disclaimer/" target="blank">{{$t('Disclaimer')}}</a>&ndash;&gt;-->
<!--&lt;!&ndash;              <a v-else href="http://www.cic.hk/chi/main/disclaimer/" target="blank">{{$t('Disclaimer')}}</a>&ndash;&gt;-->
<!--&lt;!&ndash;              <p v-show="checkDisclaimer" class="remind">{{$t('PleaseCheck')}}{{$t('Disclaimer')}}</p>&ndash;&gt;-->
<!--            </FormItem>-->

          </Form>
        </b-col>
        <b-col lg="6" md="12" >
          <p class="PDCS"><span>1.</span><span>{{$t('PersonalDataCollectionStatement1')}}</span></p>
          <p class="PDCS"><span>2.</span><span>{{$t('PersonalDataCollectionStatement2')}}</span></p>
          <p class="PDCS"><span>3.</span><span>{{$t('PersonalDataCollectionStatement3')}}</span></p>
          <p class="PDCS"><span>4.</span><span>{{$t('PersonalDataCollectionStatement4')}}</span></p>
          <Checkbox  @on-change="changeValue">
            <span>{{$t('IAgreePersonalDataCollectionStatement')}}</span>
          </Checkbox>
        </b-col>
      </b-row>
      <b-row  v-if="modelPage == 2">
        <h3 style="margin:0 auto;color: rgb(98, 138, 33);">{{$t('Disclaimer')}}</h3>
        <Divider />
        <p class="disclaimerContent">{{$t('DisclaimerContent1')}}</p>
        <p class="disclaimerContent">{{$t('DisclaimerContent2')}}</p>
        <p class="disclaimerContent">{{$t('DisclaimerContent3')}}</p>
        <p class="disclaimerContent">{{$t('DisclaimerContent4')}}</p>
      </b-row>
      <b-row style="margin: 20px">
        <b-col lg="6" md="6" sm="6" xs="6">
          <Button
            v-if="modelPage == 1"
            type="success"
            :loading="loginFormLoading"
            @click="visibleChange(false)"
            class="btn_back"
            style="background-color:rgb(157, 157, 155);border: 1px solid rgb(157, 157, 155);">
            {{$t('Back')}}
          </Button>
        </b-col>
        <b-col lg="6" md="6" sm="6" xs="6">
          <Button
            v-if="modelPage == 1"
            type="success"
            :loading="loginFormLoading"
            @click="nextFn"
            class="btn_next"
            style="background-color: #89c672;border: 1px solid #89c672;"
          >
            {{$t('Next')}}
          </Button>
        </b-col>
        <Button
          v-if="modelPage == 2"
          type="success"
          @click="agreeFn"
          long=""
          class="btn_agree"
          style="background-color: #89c672;border: 1px solid #89c672;"
        >
          {{$t('Agree')}}
        </Button>
      </b-row>
      <!-- </b-modal> -->
    </Modal>
  </div>
</template>

<script>
import forecastResult from "@/components/forecastResult.vue";
import ROUTERAPI from "@/config/routerApi";
export default {
  name: "Calculate",
  data() {
    return {
      ForecastForm: {
        projectName: "test",
        contractSum: null,
        buildingTypes: 0,
        GFA: null,
        noOfStoreyBuilding: null,
        noOfStoreyBasement: null,
        noOfStoreyPodium: null,
        noOfBlocks: null,
        startDate: "",
        endDate: "",
        ContractDuration: null,
      },
      modelPage:1,
      disclaimerHref:'',
      checkDisclaimer:false,
      isAgree:false,
      ruleForecast: {
        // projectName: [
        //   {
        //     required: true,
        //     message: this.$t('ruleProjectName'),
        //     trigger: "blur",
        //   },
        // ],
        contractSum: [
          {
            required: true,
            type: "number",
            message: this.$t("ruleContractSum"),
            trigger: "blur",
          },
          {
            type: "number",
            min: 30,
            max: 4000,
            message: this.$t("ruleContractSumRange"),
            trigger: "blur",
          },
        ],
        // buildingTypes: [
        //   {
        //     required: true,
        //     type: "number",
        //     message: "Building types cannot be empty",
        //     trigger: "change",
        //   },
        // ],
        GFA: [
          {
            required: true,
            type: "number",
            message: this.$t("ruleGFA"),
            trigger: "blur",
          },
          {
            type: "number",
            min: 900,
            max: 140000,
            message: this.$t("ruleRange") + "900 - 140000",
            trigger: "blur",
          },
        ],
        noOfStoreyBuilding: [
          {
            required: true,
            type: "number",
            message: this.$t("ruleBuildingStorey"),
            trigger: "blur",
          },
          {
            type: "number",
            min: 7,
            max: 62,
            message: this.$t("ruleRange") + "7 - 62",
            trigger: "blur",
          },
        ],
        noOfStoreyBasement: [
          {
            required: true,
            type: "number",
            message: this.$t("ruleBasementStorey"),
            trigger: "blur",
          },
          {
            type: "number",
            min: 0,
            max: 7,
            message: this.$t("ruleRange") + "0 - 7",
            trigger: "blur",
          },
        ],
        noOfStoreyPodium: [
          {
            required: true,
            type: "number",
            message: this.$t("rulePodiumStorey"),
            trigger: "blur",
          },
          {
            type: "number",
            min: 0,
            max: 5,
            message: this.$t("ruleRange") + "0 - 5",
            trigger: "blur",
          },
        ],
        noOfBlocks: [
          {
            required: true,
            type: "number",
            message: this.$t("ruleBlocks"),
            trigger: "blur",
          },
          {
            type: "number",
            min: 0,
            max: 15,
            message: this.$t("ruleRange") + "0 - 15",
            trigger: "blur",
          },
        ],
        startDate: [
          {
            required: true,
            type: "date",
            message: this.$t("ruleStartDate"),
            trigger: "blur",
          },
        ],
        ContractDuration: [
          {
            required: true,
            type: "number",
            message: "Contract Duration cannot be empty",
            trigger: "blur",
          },
        ],
        endDate: [
          {
            required: true,
            type: "date",
            message: this.$t("ruleEndDate"),
            trigger: "blur",
          },
          { validator: this.checkEndDate, trigger: "blur" },
        ],
      },
      loading: false,
      player: null,
      months: 0,
      userForm: {
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        jobTitle: "",
        recInfo: true,
      },
      ruleLogin: {
        firstName: [
          {
            required: true,
            message: this.$t('PleaseEnter'),
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: this.$t('PleaseEnter'),
            trigger: "blur",
          },
        ],
        company: [
          {
            required: true,
            message: this.$t('PleaseEnter'),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t('PleaseEnter'),
            trigger: "blur",
          },
          { type: 'email', message: this.$t('IncorrectEmailFormat'), trigger: 'blur' }
        ],
        jobTitle: [
          {
            required: true,
            message: this.$t('PleaseEnter'),
            trigger: "blur",
          },
        ],
        // checkbox: [
        //   {
        //     type: 'array', min: 1,
        //     required: true,
        //     message: "Please check",
        //     trigger: "change",
        //   },
        // ],
      },
      loginFormLoading: false,
      showLogin: false,
      language:""
    };
  },
  methods: {
    init() {
      // this.showVideo()
      let lan = sessionStorage.getItem('CICLanguage');
      this.language = lan;

      this.showlogin();

      // let user = sessionStorage.getItem("firstName");
      // console.log(user);
      // if (user) {
      //   this.showVideo();
      // } else {
      // }
    },
    showlogin() {
      // this.$bvModal.show("userModal");
      this.showLogin = true;
    },
    showVideo() {
      this.$bvModal.show("videoModal");
    },
    hiddenLogin() {
      // this.$bvModal.hide("userModal");
      this.showLogin = false;
    },
    hiddenVideo() {
      this.$bvModal.hide("videoModal");
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.ForecastForm));
    },
    onReset(event) {
      event.preventDefault();
      let ForecastForm = {
        contractSum: null,
        buildingTypes: 0,
        GFA: null,
        noOfStoreyBuilding: null,
        noOfStoreyBasement: null,
        noOfStoreyPodium: null,
        noOfBlocks: null,
        startDate: "",
        endDate: "",
        ContractDuration: null,
      };
      this.$set(this, "ForecastForm", ForecastForm);
    },
    validation(key) {
      if (this.ForecastForm[key]) {
        return true;
      }
      return false;
    },
    submitForecastForm() {
      let self = this;
      this.$refs["ForecastForm"].validate((valid) => {
        if (valid) {
          this.ForecastFormLoading = true;
          let ForecastForm = this.ForecastForm;
          let query = {
            Name: ForecastForm.projectName,
            ContractSum: ForecastForm.contractSum * 1000000,
            GFA: ForecastForm.GFA,
            BuildingTypes: ForecastForm.buildingTypes,
            NoOfStorey: ForecastForm.noOfStoreyBuilding,
            NoOfStoreyPodium: ForecastForm.noOfStoreyPodium,
            NoOfStoreyBasement: ForecastForm.noOfStoreyBasement,
            NoOfBlock: ForecastForm.noOfBlocks,
            StartYear: new Date(ForecastForm.startDate).getFullYear(),
            StartMonth: new Date(ForecastForm.startDate).getMonth() + 1,
            StartDay: new Date(ForecastForm.startDate).getDate(),
            EndYear: new Date(ForecastForm.endDate).getFullYear(),
            EndMonth: new Date(ForecastForm.endDate).getMonth() + 1,
            EndDay: new Date(ForecastForm.endDate).getDate(),
            ContractDuration: ForecastForm.ContractDuration,
          };
          self.$http
            .post(ROUTERAPI.compareForecast1, query)
            .then((res) => {
              if (res.status === 200 && res.data && res.data.result) {
                document.querySelector("#forecastResult").scrollIntoView();
                this.ForecastFormLoading = false;
                this.months = Math.round(Number(res.data.result) * 100);
                this.$Message.success("Forecast form calculation successful");
              }
            })
            .catch((e) => {
              this.ForecastFormLoading = false;
              console.log(e);
            });
        }
      });
    },
    nextFn() {

      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.userInfoFn(this.userForm);
            // this.modelPage = 2;
        }else{
          this.$bvToast.toast(this.$t('PleaseComplete'),{
            title:"Remained",
            toaster: 'b-toaster-top-center',
          })
        }
      });
    },
    userInfoFn(query){
      this.$http.post(ROUTERAPI.userinfo, query)
        .then((res) => {
          if (res.status === 200 && res.data == 'success') {
            this.modelPage = 2;
          }else{
            this.$bvToast.toast("Error",{
              title:"Remained",
              toaster: 'b-toaster-top-center',
            })
          }
        })
        .catch((error) => {
          let errorStatus = '';
          let errorContent = '';
          if(error && error.response){
            errorStatus = error.response.status || '';
            switch (error.response.status) {
              case 400:
                errorContent = 'Bad Request!';
                break;
              case 401:
                errorContent = 'Unauthorized, Please login again !';
                break;
              case 404:
                errorContent = 'No Found !';
                break;
              case 408:
                errorContent = 'Request Timeout, Please try again !';
                break;
              case 500:
                errorContent = 'Internal Server Error !';
                break;
              case 503:
                errorContent = 'Service Unavailable !';
                break;
              default:
                errorContent = 'Unknown error, please contact administrator !';
            }
          }else{
              errorStatus = 'No status';
              errorContent = 'The request failed, Please check the network status !';
          }
          this.$bvToast.toast(errorContent,{
            title:"Error: " + errorStatus,
            toaster: 'b-toaster-top-center',
          })
        });
    },
    agreeFn(){
      this.isAgree = true;
      this.hiddenLogin();
      this.showVideo();
    },
    changeValue(status){
      this.userForm.recInfo = !status;
      this.checkDisclaimer = !status;
    },
    checkMonth() {
      let ContractDuration = this.getDatemonth(
        this.ForecastForm.startDate,
        this.ForecastForm.endDate
      );
      this.$set(this.ForecastForm, "ContractDuration", ContractDuration);
    },
    getDatemonth(Sdate, Edate) {
      if (
        Sdate === "" ||
        Edate === "" ||
        new Date(Edate).getTime() <= new Date(Sdate).getTime()
      ) {
        return "";
      }
      // 获取两个时间的月份
      let sMongth = new Date(Sdate).getMonth() + 1;
      let eMongth = new Date(Edate).getMonth() + 1;
      let diffMonth = eMongth - sMongth;
      // 获取两个时间的年份
      let sYear = new Date(Sdate).getFullYear();
      let eYear = new Date(Edate).getFullYear();
      let diffYear = eYear - sYear;
      // 计算月数
      return diffYear * 12 + diffMonth;
    },
    checkEndDate(rule, value, callback) {
      if (value === "") {
        callback(new Error("Please select date"));
      } else if (
        new Date(value).getTime() <=
        new Date(this.ForecastForm.startDate).getTime()
      ) {
        callback(new Error("The end date is not less than the start date"));
      } else {
        callback();
      }
    },
    visibleChange(status){
      if(!status && !this.isAgree){
        window.open('','_self').close()
      }
    }
  },
  mounted() {
    this.init();
  },
  components: { forecastResult },
};
</script>

<style lang="less" >
  .ivu-modal{
    max-width: 90%;
    margin-top: 5% !important;
  }
.calculate {
  position: relative;
  .content {
    background: #fff;
    padding: 100px 0;
    text-align: left;
    padding-bottom: 60px;
    .formTitle {
      font-size: 22px;
      font-weight: 800;
      text-align: center;
    }
  }


}
.remind{
  color: red;
  margin-top: -16px;
  margin-left: 20px;
}
.PDCS{
  margin-bottom: 10px;
  display: flex;
}
  .disclaimerContent{
    padding: 0 20px 20px;
  }

.btn_back.ivu-btn-success{
  float: right
}

.btn_agree.ivu-btn-success{
  margin:0 370px
}
  /*@media screen and (max-width: 76px){*/
  /*  .ivu-modal{*/
  /*    margin-top:2rem !important*/
  /*  }*/
  /*}*/
@media screen and (max-width: 600px){

  .btn_agree{
    margin: 0 auto;
  }

}
  @media screen and (max-width: 428px){
  .ivu-modal{
    width: 100% !important;
    margin: 0 auto !important;
  }
  .model_title{
    padding-left: 15px;
  }
  .btn_back{
    float: none;
    width: 100%;
  }
  .btn_next{
    width: 100%;
    margin-top: 15px;
  }
  .btn_agree{
    margin: 0 auto;
  }

}

</style>
