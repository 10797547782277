<template>
    <div class="main">
        <Header @changeRouter="changeRouterFn" :currentMenuNm="currentMenu"></Header>
        <div class="welcome-section-area">
            <div v-if="routerName == '/aboutTheIndex'" class="about_title">
                <b-container class="bv-example-row ">
                    {{$t('MethodologyTitle')}}
                    <div class="about_subtitle">
                        <p>{{$t('SubmitTitle1')}}</p>
                        <p>{{$t('SubmitTitle2')}}</p>
                    </div>
                    <Button
                        type="success"
                        @click="goCalculate"
                        style="background-color: #89c672;border: 1px solid #89c672;"
                    >{{ $t("Start") }}</Button>
                </b-container>
            </div>
            <div v-else class="CIC_title">
                <b-container class="bv-example-row ">
                    {{$t('MethodologyTitle')}}
                    <p class="CIC_subtitle">{{$t(subtitleKey)}}</p>
                </b-container>
            </div>
            <router-view />
        </div>
        <div class="footer">
            <b-container class="bv-example-row ">
            © 2021 Construction Industry Council. All rights reserved.
                <a href="mailto:coop@cic.hk" style="margin-left: 50px">{{$t('Support')}}</a>
            </b-container>
        </div>
    </div>
</template>

<script>
    import Header from '@/components/Header'
    export default {
        name: "homepage",
        data(){
            return{
                routerName:"",
                currentMenu:null,
                subtitleKey:''
            }
        },
        components:{
            Header
        },
    created() {

    },
    mounted() {
        if(this.$route.path != '/calculate'){
            let page = sessionStorage.getItem('CICRouterName') || '/aboutTheIndex';
            this.changeRouterFn(page);
            this.$router.push(page);
        }

    },
    methods:{
        changeRouterFn(routerName){
            this.routerName = routerName;
            this.subtitleKey = routerName.substring(1)
        },
        goCalculate(){
            let href = window.location.href.split('#')[0];
            let newHref = href + '#/calculate';
            window.open(newHref)
            // this.changeRouterFn('/calculate');
            // sessionStorage.setItem('CICRouterName','/calculate');
            // this.$router.push('/calculate');
            // this.currentMenu = 3;
        }
    }
}
</script>
<style lang="less">
    .ivu-divider-inner-text{
        font-family:'Poppins', sans-serif !important;
    }
    .ivu-radio-checked .ivu-radio-inner{
        border-color: #89c672 !important;
    }
    .ivu-radio-inner:after{
        background-color: #89c672 !important;
    }

    @font-face {
        font-family: "Material Design Icons";
        src: url("../../static/MaterialDesign-Webfont-master/fonts/materialdesignicons-webfont.eot");
        src: url("../../static/MaterialDesign-Webfont-master/fonts/materialdesignicons-webfont.eot?#iefix&v=5.8.55") format("embedded-opentype"),
        url("../../static/MaterialDesign-Webfont-master/fonts/materialdesignicons-webfont.woff2?v=5.8.55") format("woff2"),
        url("../../static/MaterialDesign-Webfont-master/fonts/materialdesignicons-webfont.woff?v=5.8.55") format("woff"),
        url("../../static/MaterialDesign-Webfont-master/fonts/materialdesignicons-webfont.ttf?v=5.8.55") format("truetype");
        font-weight: normal;
        font-style: normal;
    }
    .mdi {
        font-family: "Material Design Icons";
        font-style: normal;
    }
    .mdi-chart-scatter-plot::before {
        content: "\F0E92";
    }
    .mdi-monitor-dashboard::before {
        content: "\F0A07";
    }
    .mdi-calculator-variant-outline::before {
        content: "\F15A6";
    }
    .mdi-bullseye-arrow::before {
        content: "\F08C9";
    }
    .mdi-book-open-page-variant-outline::before {
        content: "\F15D6";
    }
    .mdi-television-play::before {
        content: "\F0ECF";
    }
    .mdi-folder-table-outline::before {
        content: "\F12E4";
    }
    .mdiStyle{
        font-size: 100px;
        color: rgb(137, 198, 57);
    }

</style>
<style scoped lang="less">
    .fontSize70{
        font-size: 70px;
        font-weight: 600;
        line-height: 80px;
    }
    .fontSize56{
        font-weight: 600;
        font-size: 56px;
        line-height: 60px;
    }
    .fontSize32{
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
    }
.main{
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    /*background-image: url("../assets/bg4.png");*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
    /*background-position: center center;*/
    /*background-attachment: fixed;*/
    min-height: 100vh;
    padding-bottom: 40px;

}
    .main:before {
        content: ' ';
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url("../assets/bg8.jpg") center 0 no-repeat;
        background-size: cover;
    }
.CIC_title{
    margin-top: 100px;
    height: 55vh;
    min-height: 55vh;
    color: #FFF;
    background: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    .bv-example-row{
        .fontSize70();
    }
}
.CIC_subtitle{
    font-size: 50px;
    color: #e9e9e9;
    font-weight: normal;
}
.about_title{
    padding-top: 90px;
    height: 100vh;
    min-height: 100vh;
    color: #FFF;
    background: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    .bv-example-row{
        .fontSize70();
    }
    .about_subtitle{
        width: 80%;
        font-size: 22px;
        font-weight: 300;
        line-height: 1.7;
        margin: 0 auto;
        p{
            margin: 30px 0;
        }
    }
}
@media screen and (max-width: 992px) {
    .CIC_title{
        margin-top: 83px;
        height: 45vh;
        min-height: 45vh;
        .bv-example-row{
            font-weight: 600;
            font-size: 30px;
            line-height: 40px;
        }
    }
    .about_title{
        .bv-example-row{
            font-weight: 600;
            font-size: 30px;
            line-height: 40px;
        }
        .about_subtitle{
            font-size: 14px;
            font-weight: 300;
            p{
                margin-bottom: 30px;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .CIC_title{
        /*margin-top: 86px;*/
        .bv-example-row{
            .fontSize56()
        }
    }
    .about_title{
        .bv-example-row{
            .fontSize56()
        }
        .about_subtitle{
            font-size: 22px;
            font-weight: 300;
            p{
                margin-bottom: 30px;
            }
        }
    }
}
@media screen and (max-width: 428px){
    .CIC_title{
        /*margin-top: 86px;*/
        .bv-example-row{
            .fontSize32();
        }
    }
    .CIC_subtitle{
        font-size: 26px;
    }
    .about_title{
        .bv-example-row{
            .fontSize32()
        }
        .about_subtitle{
            font-size: 14px;
            font-weight: 300;
            p{
                margin-bottom: 15px;
            }
        }
    }
}
@media screen and (max-width: 320px){
    .CIC_title{
        /*margin-top: 86px;*/
        .bv-example-row{
            font-weight: 500;
            font-size: 26px;
            line-height: 29px;
        }
    }
    .CIC_subtitle{
        font-size: 22px;
    }
    .about_title{
        .bv-example-row{
            font-weight: 500;
            font-size: 26px;
            line-height: 29px;
        }
        .about_subtitle{
            font-size: 14px;
            font-weight: 300;
            p{
                margin:10px 0;
            }
        }
    }
}
.footer{
    background-color: #F0F0F0;
    font-weight: 400;
    font-size: 15px;
    color: #555555;
    line-height: 20px;
    text-align: center;
    padding: 10px 0;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}
/*.main{*/
/*    background-image: url("../assets/bg1.png");*/
/*    background-size: 1920px ;*/
/*    background-repeat: no-repeat;*/
/*    background-position: 0 100px ;*/
/*}*/
</style>
