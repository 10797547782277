<template>
  <div class="forecastResult">
    <div class="resultTitle">{{ $t('ConstructionTimePerformanceIndex') }}</div>
    <p style="color: #808695; margin-top: 10px;font-size: 16px;
    font-weight: 300;">{{$t('ECPIInfo')}}
    </p>
    <div class="four_angle_bg2">
      <div class="four_angle_bottom_bg">
        {{ monthNum || 0 }}
        <!-- <span v-if="title != 'CTP Index'">months</span> -->
      </div>
      <!-- <BeautifulTitle titleName="EV Charger"></BeautifulTitle> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    monthNum: Number,
    // title: {
    //   type: String,
    //   default: "Estimated construction performance index",
    // }
  },
};
</script>
<style lang="less" scoped>
@titleRedColor: rgba(137, 198, 114, 0.7);
@titleBlueColor: rgba(157, 157, 155, 0.7);
.forecastResult {
  position: relative;
  top: 20px;
  .resultTitle {
    font-size: 24px;
    font-style: italic;
    color: #89c672;
    font-weight: 500;
  }

  .four_angle_bg2 {
    height: 140px;
    /*margin-left: 20%;*/
    /*width: 50%;*/
    margin-top: 10px;
    padding: 20px;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 60px;
      height: 30px;
      border-left: 3px solid @titleBlueColor;
      border-top: 3px solid @titleBlueColor;
      position: absolute;
      left: 0;
      top: 0;
    }
    &::after {
      content: "";
      display: block;
      width: 57px;
      height: 27px;
      border-left: 3px solid @titleRedColor;
      border-top: 3px solid @titleRedColor;
      position: absolute;
      left: 3px;
      top: 3px;
    }
    .four_angle_bottom_bg {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      text-align: center;
      line-height: 140px;
      color: #89c672;
      font-weight: 800;
      font-size: 60px;
      &::before {
        content: "";
        display: block;
        width: 60px;
        height: 30px;
        border-right: 3px solid @titleBlueColor;
        border-bottom: 3px solid @titleBlueColor;
        position: absolute;
        right: 0;
        bottom: 0;
      }
      &::after {
        content: "";
        display: block;
        width: 57px;
        height: 27px;
        border-right: 3px solid @titleRedColor;
        border-bottom: 3px solid @titleRedColor;
        position: absolute;
        right: 3px;
        bottom: 3px;
      }
    }
  }
}
</style>
