import URLOBJ from './apiUrl'


let minPath = '';

if(URLOBJ.type == 'dev'){
  minPath = 'cic/'
}
const ROUTERAPI = {
  compareForecast: URLOBJ.api + minPath + 'forecast/',
  compareForecast1: URLOBJ.api + minPath +  'forecast1/', // 后期新增接口
  comparePreformance: URLOBJ.api + minPath + 'ctp/',
  userinfo: URLOBJ.api + minPath + 'userinfo',
};
global.ROUTERAPI = ROUTERAPI;
export default ROUTERAPI
