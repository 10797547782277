// import {Notice} from 'iview';
// import {Message} from 'iview';

// global.ERRORMSG = function(errorTitle, errorContent, time = 60){
//   Notice.error({
//     title: 'Error',
//     duration: time,
//     desc: '<span style="font-size: 15px;color: #ff7940;font-weight: bold;">Title: </span>' + errorTitle + '<br/>' +
//       '<span style="font-size: 15px;color: #ff7940;font-weight: bold;">Content: </span> ' + errorContent
//   });
// };

global.REQUESTFAILED = function(error, time = 60){

  let errorStatus = '';
  let errorContent = '';
  if(error && error.response){
    errorStatus = error.response.status || '';
    switch (error.response.status) {
      case 400:
        errorContent = 'Bad Request!';
        break;
      case 401:
        errorContent = 'Unauthorized, Please login again !';
        break;
      case 404:
        errorContent = 'No Found !';
        break;
      case 408:
        errorContent = 'Request Timeout, Please try again !';
        break;
      case 500:
        errorContent = 'Internal Server Error !';
        break;
      case 503:
        errorContent = 'Service Unavailable !';
        break;
      default:
        errorContent = 'Unknown error, please contact administrator !';
    }
  }else{
    errorStatus = 'No status';
    errorContent = 'The request failed, Please check the network status !';
  }
  this.$bvToast.toast(e,{
    title:"Remained",
    toaster: 'b-toaster-top-center',
  })
  // Message.error({
  //   duration: time,
  //   background: true,
  //   closable: true,
  //   content: '<span style="font-size: 15px;color: #ed3e35;font-weight: bold;">Status: </span>' + errorStatus + '<br/>' +
  //     '<span style="font-size: 15px;color: #ed3e35;font-weight: bold;">Content: </span> ' + errorContent
  // });

};
global.ADDSEARCH = function(obj) {
  if (typeof obj != "object") {
    return "";
  }
  var query = "?";
  var num = 0;
  for (var k in obj) {
    if (obj[k] && num === 0) {
      query = query + k + "=" + obj[k];
      num++;
    } else if (obj[k]) {
      query = query + "&" + k + "=" + obj[k];
    }
  }
  return query;
};

global.FORMATDATA = function(dateStr) {
  if (
    !dateStr ||
    new Date(dateStr).getTime() < new Date("1970-01-01").getTime()
  ) {
    return "";
  }
  var d = new Date(dateStr);
  if (!isNaN(d)) {
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var h = d.getHours();
    var m = d.getMinutes();
    var s = d.getSeconds();
    return (
      year.toString() +
      "-" +
      autoAddZero(month + 1) +
      "-" +
      autoAddZero(day) +
      " " +
      autoAddZero(h) +
      ":" +
      autoAddZero(m) +
      ":" +
      autoAddZero(s)
    );
  }
};
global.FORMATTIME = function(dateStr) {
  if (
    !dateStr ||
    new Date(dateStr).getTime() < new Date("1970-01-01").getTime()
  ) {
    return "";
  }
  var d = new Date(dateStr);
  if (!isNaN(d)) {
    var h = d.getHours();
    var m = d.getMinutes();
    var s = d.getSeconds();
    return (
      autoAddZero(h) +
      ":" +
      autoAddZero(m) 
      // + ":" +
      // autoAddZero(s)
    );
  }
};
global.FORMATDATAUTC = function(dateStr) {
    if (!dateStr || new Date(dateStr).getTime() < new Date('1970-01-01').getTime()) {
        return '';
    }
    var d = new Date(dateStr);
    if (!isNaN(d)) {
        var year = d.getUTCFullYear();
        var month = d.getUTCMonth();
        var day = d.getUTCDate();
        var h = d.getUTCHours();
        var m = d.getMinutes();
        var s = d.getSeconds();
        return (year.toString() + "-" + autoAddZero(month + 1) + "-" + autoAddZero(day) + ' ' + autoAddZero(h) + ':' + autoAddZero(m) + ':' + autoAddZero(s));
    }
}

function autoAddZero(num) {
  let digit = FOMATNUMBER(num);
  if (digit >= 0 && digit < 10) {
    return "0" + digit;
  }
  return digit;
}

function FOMATNUMBER(num) {
  var numb = Number(num);
  if (isNaN(numb)) {
    return 0;
  } else {
    return numb;
  }
}
global.MYBROWSER = () => {
  var u = navigator.userAgent;
  return {
    //移动终端浏览器版本信息
    trident: u.indexOf("Trident") > -1, //IE内核
    presto: u.indexOf("Presto") > -1, //opera内核
    webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
    gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
    android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或uc浏览器
    iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
    iPad: u.indexOf("iPad") > -1, //是否iPad
    webApp: u.indexOf("Safari") == -1 //是否web应该程序，没有头部与底部
  };
};
global.REMOVESLASH = str => {
  return str.substring(1, str.length);
};

//财务数据格式, 千分位分割,且带有小数位两位,分正负数
global.ToThousands = function(num){
  if(num == null){
    return false
  }

  return ProcessingData(num, 2 );
};
function ProcessingData(num, decimals ){
  //decimals 保留多少位小数
  let isNegative = num < 0; //判断是否为负
  num = Math.abs(num);      //取正

  var result = '';
  num = Number(num.toString() || 0).toFixed(decimals);
  var numAry = num.toString().split(".");
  var preNum = numAry[0];
  var lastNum = numAry[1];

  while (preNum.length > 3) {
    result = ',' + preNum.slice(-3) + result;
    preNum = preNum.slice(0, preNum.length - 3);
  }
  if (preNum) {
    result = preNum + result;
  }
  result=result+"."+lastNum;
  //如果是负数,加上负号
  if(isNegative){
    result = '-' + result
  }

  return result;
}
