<template>
  <div id="app">
    <router-view />
<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>

<style lang="less">
  body{
    overflow: unset !important;
    padding-right: 0 !important;
  }
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family:'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
  width: 100%;
}
@whiteColor:rgba(230, 230, 230,1);
@scrollColor:rgb(137, 198, 114);
  /* 更改滚动条默认样式start */
  ::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background: @scrollColor;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2.5px @whiteColor;
    box-shadow: inset 0 0 2.5px @whiteColor;
    border-radius: 2.5px;
    background-color: @whiteColor;
  }
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 2.5px @whiteColor;
    box-shadow: inset 0 0 2.5px @whiteColor;
    border-radius: 2.5px;
    background-color: @scrollColor !important;
    opacity: 0.7;
    transition: opacity ease-in-out 200ms;
  }
  ::-webkit-scrollbar-thumb:hover {
    /* background: rgba(26, 86, 116, 1); */
    background: rgba(64, 79, 93, 0.7);
    opacity: 1;
  }
</style>
