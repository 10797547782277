<template>
  <div>
    <div :id="chartID" style="height: 380px;width:100%;"></div>
  </div>
</template>
<script>
import Highcharts from "highcharts";
// import Highcharts from "highcharts/highstock";
// import HighchartsMore from "highcharts/highcharts-more";
// import HighchartsDrilldown from "highcharts/modules/drilldown";
// import Highcharts3D from "highcharts/highcharts-3d";

// HighchartsMore(Highcharts);
// HighchartsDrilldown(Highcharts);
// Highcharts3D(Highcharts);
export default {
  name: "lineCharts",
  props: {
    chartID: {
      type: String,
      default: "container",
    },
    title: {
      type: String,
      default: "Title",
    },
    xAxisData: Array,
    chartData: Array,
    color: String,
  },
  data() {
    return {
      charts: null,
      options: {
        // 去掉水印
        credits: {
          enabled: false,
        },
        chart: {
          type: "line",
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
        legend: {
          itemStyle: {
            // color: "#fff",
          },
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          lineColor:'rgba(128, 134, 149,0.3)',
          categories: [
            "2009",
            "2010",
            "2011",
            "2012",
            "2013",
            "2014",
            "2015",
            "2016",
            "2017",
            "2018",
            "2019",
          ],
        },
        yAxis:{
          title: '',
          lineColor:'rgba(128, 134, 149,0.3)',
          lineWidth:1,
          gridLineColor:'rgba(0,0,0,0.1)',
        },
        series: [
          {
            name: this.title,
            color: this.color,
            data: [],
          },
        ],
      },
    };
  },
  methods: {
    init() {
      let seriesData = [];
      let xAxis = [];
      let xAxisData = this.xAxisData;
      if (xAxisData && xAxisData.length) {
        for (let j = 0; j < xAxisData.length; j++) {
          xAxis.push(xAxisData[j]);
        }
      }
      this.checkChartData();
      if (this.charts) {
        this.charts.update({
          xAxis: {
            categories:
              xAxis.length > 0 ? xAxis : this.options.xAxis.categories,
          },
          series: seriesData.length > 0 ? seriesData : this.options.series,
        });
      } else {
        let options = this.options;
        options.series = this.chartData || this.options.series;
        options.xAxis.categories =
          this.xAxisData || this.options.xAxis.categories;
        this.$set(this, "options", options);
        this.charts = new Highcharts.Chart(this.chartID, this.options);
      }
      this.$emit("setOption", this.setOption);
    },
    checkChartData() {
      let chartData = this.chartData;
      for (let i = 0; i < chartData.length; i++) {
        let row = chartData[i];
        let rowList = row.data;
        let rowListTempData = [];
        for (let j = 0; j < rowList.length; j++) {
          let tempVal = this.formatNumber(rowList[j], 2);
          rowListTempData.push(tempVal);
        }
        row.data = rowListTempData;
      }
      this.$set(this, "chartData", this.chartData);
    },
    formatNumber(val, num) {
      return Number(val.toFixed(num));
    },
    setOption(obj) {
      let options = this.options;
      if (options.xAxis && options.xAxis.categories) {
        options.xAxis.categories =
          obj.xAxisCategories || options.xAxis.categories;
      }
      if (options.series && options.series[0] && options.series[0].data) {
        options.series = obj.series || this.options.series;
      }
      this.$set(this, "options", options);
      if (this.charts) this.charts.update(options);
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    chartData(newData) {
      // console.log("watch");
      if (newData) {
        this.init();
      }
    },
  },
};
</script>
