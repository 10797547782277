import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import Axios from 'axios';
import tools from './tools/tools'

Vue.use(ViewUI);
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.config.productionTip = false;
Vue.prototype.$http = Axios;


// 引入多语言
import VueI18n from "vue-i18n";

import enLocale from "view-design/src/locale/lang/en-US";
import twLocale from "view-design/src/locale/lang/zh-TW";

import HK from "../static/language/ZH-HK";
import US from "../static/language/EN-US";

// 引入多语言3
Vue.use(VueI18n);
Vue.locale = () => { };

const messages = {
  EN_US: Object.assign(US, enLocale),
  ZH_HK: Object.assign(HK, twLocale)
};
// 设置多语言参数，创建 Vuei18n 的实例5
const i18n = new VueI18n({
  locale: localStorage.getItem("CICLanguage") || "EN_US",
  messages: messages // set locale messages
});


new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');

